import React from 'react';
import * as s from './s.module.css';
import cx from 'classnames';

class Gold extends React.Component {
    render() {
        return (
            <section className={s.goldContainer}>
                <div className={s.goldContent}>
                    <p className={s.mb_30}>
                        The <span className={s.bold}>$GOLD</span> token can be thought of as a rewards/loyalty program, where <span className={s.bold}>1 $GOLD</span> is equal to <span className={s.bold}>$1 USDT.</span>
                    </p>
                    <p>
                        Players will be able to earn $GOLD by competing in a free-to-play Weekly Leaderboard Challenge or by grinding on our ETH tables. Players accrue $GOLD that they can either use to play in $GOLD table games or redeem for cash.
                    </p>
                    <p className={s.mb_45}>
                        We are happy to announce that <span className={cx(s.bold, s.underline)}>ALL PLAYERS are eligible</span> to compete in our new version of our P2E game to earn $GOLD.
                    </p>
                    <p>
                        The <span className={s.bold}>$GOLD</span> token will be rolled out in 2 phases: 
                    </p>

                    <div  className={cx(s.mb_45,s.tableContainer, s.ml_45)}>
                        <div className={s.myTableList}>
                            <div className={s.row}>
                                <div className={s.numberList}><p className={s.blue}>Phase 1:</p></div>
                                <div>
                                    <p>
                                    Initially the <span className={s.bold}>$GOLD</span> token will exist only within the <span className={s.bold}>Virtue platform</span> and will be redeemable directly in the client prior to the end of the promotion. However - all players will be able to choose to either redeem part or all of their <span className={s.bold}>$GOLD</span> or use it to play <span className={s.bold}>$GOLD table games</span>.
                                    </p>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.numberList}><p className={s.blue}>Phase 2:</p></div>
                                <div>
                                    <p>
                                        The <span className={s.bold}>$GOLD tokens</span> on the application will be swapped for an <span className={s.bold}>ERC-20</span> token and Virtue will be creating liquidity pools for <span className={s.bold}>$GOLD</span> on <span className={s.bold}>Polygon’s QuickSwap</span> exchange so players can exchange for low fees.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className={cx(s.mb_30)}>
                        <span className={cx(s.subtitle, s.blue, s.bold)}>Redeem: </span><br/>Players who earn $GOLD will be able to redeem their $GOLD directly in the application to receive $USDT 
                    </p>
                    <p className={cx(s.mb_30)}>
                        <span className={cx(s.subtitle, s.blue, s.bold)}>Play:</span><br/>In addition Virtue will be deploying $GOLD table games that players can use their $GOLD tokens to compete for additional special prizes. 
                    </p>
                    <p>
                        <span className={cx(s.subtitle, s.blue, s.bold)}>Purchase Virtue Poker’s Genesis NFT Avatars:</span><br/>Virtue will be announcing shortly our genesis mint of NFT avatars that players will be able to purchase using $GOLD to enhance their P2E earning potential. 
                    </p>
                </div>
            </section>
        );
    }
}

export default Gold;
