import React from "react"

import Layout from "../../layout/default"
import Seo from "../../components/seo"
import GoldTokenPage from '../../components/TokenEconomy/goldTokenPage'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="$GOLD Token" />
    <GoldTokenPage location={location}/>
  </Layout>
)

export default IndexPage
