// extracted by mini-css-extract-plugin
export var blue = "s-module--blue--3a5b7";
export var bold = "s-module--bold--cc219";
export var contentAnimation = "s-module--contentAnimation--a4a03";
export var goldContainer = "s-module--goldContainer--ad1bc";
export var goldContent = "s-module--goldContent--95ca7";
export var mb_15 = "s-module--mb_15--1a887";
export var mb_30 = "s-module--mb_30--e3b1f";
export var mb_45 = "s-module--mb_45--6835c";
export var mb_60 = "s-module--mb_60--be7a8";
export var ml_45 = "s-module--ml_45--57566";
export var myTableList = "s-module--myTableList--74f4e";
export var numberList = "s-module--numberList--7844a";
export var row = "s-module--row--5f12d";
export var small = "s-module--small--a3d67";
export var subtitle = "s-module--subtitle--aab2a";
export var tableContainer = "s-module--tableContainer--287ba";
export var underline = "s-module--underline--fa606";